'use client';

import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocale, useTranslations } from 'next-intl';
import { EmptyFollowedProject } from './EmptyFollowedProject';
import { FollowedProjectsSkeleton } from './FollowedProjectsSkeleton';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';
import { useApiErrorContext, useAuthenticatedContext } from '@/app/components/Provider';
import { fetchCollection } from '@/app/actions/server';
import type { ProjectType } from '~/model/ProjectType';
import { ApiRoute } from '@/app/utils/request/api-routes';
import { MainColumn } from '@/app/components/MainColumn';
import { AcceptLanguage } from '~/const/locale';
import { ProjectsScroller } from '@/app/[locale]/(home)/components/ProjectsScroller/ProjectsScroller';
import { ProjectItemContextEnum } from '@/app/[locale]/(home)/components/ProjectsScroller/model';
import appConst from '~/const/appConst';

export const FollowedProjects = () => {
  const t = useTranslations('HOMEPAGE');

  const locale = useLocale();
  const { setError } = useApiErrorContext();

  const [followedProjects, setFollowedProjects] = useState<ProjectType[]>();

  const { user, isAuthenticated, isAuthenticating } = useAuthenticatedContext();

  const authenticatedAsMainUser = isAuthenticatedAsMainUser(
    user,
    isAuthenticated,
  );

  const { PROJECTS } = ApiRoute;

  const getFollowedProjects = useCallback(async () => {
    if (!user?.id)
      return;

    const response = await fetchCollection<ProjectType>({
      pathname: PROJECTS,
      revalidate: 0,
      headers: {
        acceptLanguage: AcceptLanguage[locale],
      },
      searchParams: {
        'organization.name': appConst.organizationName,
        followed_up_by_user: user.id,
        active_order_by_interaction_start_at: 'desc',
      },
    });

    if (response['hydra:description']) {
      return setError(response);
    }

    setFollowedProjects(response['hydra:member']);
  }, [user?.id]);

  const displaySkeleton
    = isAuthenticating
    || (!followedProjects && authenticatedAsMainUser);

  const renderFollowedProjects = followedProjects?.length
    ? (
      <>
        <h2 className="vp-title-m">{t('FOLLOWED_PROJECTS')}</h2>
        <ProjectsScroller
          data={followedProjects?.slice(0, 5)}
          instanceName={ProjectItemContextEnum.followed}
        />
      </>
      )
    : (
      <EmptyFollowedProject />
      );

  useEffect(() => {
    getFollowedProjects();
  }, [getFollowedProjects]);

  return (
    <section
      data-testid="followed-projects"
      className={classNames('followed-projects', {
        'followed-projects--empty':
          !displaySkeleton && !followedProjects?.length,
      })}
    >
      <MainColumn>
        {displaySkeleton
          ? (
            <FollowedProjectsSkeleton />
            )
          : (
              renderFollowedProjects
            )}
      </MainColumn>
    </section>
  );
};
