import { VtmnSkeleton } from '@vtmn/react';

const SkeletonItem = () => (
  <div className="react-horizontal-scrolling-menu--item followed-scroller__item" data-testid="skeleton-item">
    <article className="project-item">
      <span className="project-item__link">
        <header className="project-item__header">
          <span className="project-item__media">
            <span className="skeleton__media vtmn-skeleton" />
          </span>
        </header>
      </span>
      <footer className="project-item__body">
        <VtmnSkeleton width="100%" height="16px" />
        <VtmnSkeleton width="100%" height="16px" />
      </footer>
    </article>
  </div>
);

export const FollowedProjectsSkeleton = () => (
  <div className="skeleton-template followed-skeleton">
    <VtmnSkeleton width="256px" height="38px" />
    <div className="react-horizontal-scrolling-menu--wrapper followed-scroller">
      <span className="projects-scroller__arrows projects-scroller__arrows--left projects-scroller__arrows--disabled">
        <span className="skeleton__arrow vtmn-skeleton" />
      </span>
      <div className="react-horizontal-scrolling-menu--scroll-container followed-scroller__container">
        <SkeletonItem />
        <div className="react-horizontal-scrolling-menu--separator" />
        <SkeletonItem />
        <div className="react-horizontal-scrolling-menu--separator" />
        <SkeletonItem />
        <div className="react-horizontal-scrolling-menu--separator" />
        <SkeletonItem />
        <div className="react-horizontal-scrolling-menu--separator" />
        <SkeletonItem />
        <div className="react-horizontal-scrolling-menu--separator" />
      </div>
      <span className="projects-scroller__arrows projects-scroller__arrows--right projects-scroller__arrows--disabled">
        <span className="skeleton__arrow vtmn-skeleton" />
      </span>
    </div>
  </div>
);
