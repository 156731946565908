import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale } from 'next-intl';
import { useApiErrorContext, useAuthenticatedContext } from '../components/Provider';
import { fetchCollection, fetchItem } from '../actions/server';
import type { SearchParams } from '../utils/request/url';
import type { UriTemplate } from '../utils/request/api-routes';
import type {
  CriteriaChoiceUserToDelete,
  UpdatingCriteriasState,
  UpdatingDataCriteria,
  UpdatingDataValue,
} from '~/components/Profile/model';
import {
  RequestStatus,
  useRequestState,
} from '~/hooks/request/useRequestState';
import type { CriteriaChoiceUserType } from '~/model/CriteriaChoiceType';
import type { UserType } from '~/model/UserType';
import { filterUserCriteria } from '~/utils/user-criteria/filter';
import { isMainUser } from '~/utils/user/user';
import { AcceptLanguage } from '~/const/locale';

export function useUserCriteria({
  child,
  userCriteriaFilter,
  onSubmit = ({ callback }) => Promise.resolve(callback()),
}: {
  child?: UserType;
  userCriteriaFilter?: SearchParams;
  onSubmit?: ({ callback }: { callback: () => Promise<void> }) => Promise<void>;
}) {
  const locale = useLocale();
  const { user } = useAuthenticatedContext();
  const { setError } = useApiErrorContext();

  const { status: getStatus } = useRequestState();
  const { status: submitStatus } = useRequestState();

  const [userCriteria, setUserCriteria] = useState<CriteriaChoiceUserType[]>();

  const currentUser = child || user;

  const updateUserCriteria = (
    data: Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>,
  ) => {
    setUserCriteria(state => filterUserCriteria(state || [], data));
  };

  const submit = async (updatingData: UpdatingCriteriasState) => {
    const data: UpdatingDataCriteria[] = Object.keys(updatingData).reduce(
      (acc, uri) => {
        acc.push({ ...updatingData[uri] });
        return acc;
      },
      [],
    );

    const items: Array<UpdatingDataCriteria> = data.filter(item => !item.ignore);

    const promise = await Promise.all(
      items.map(async (item) => {
        const criterion = item.value;
        const criterionChoiceId = item.userCriteriaChoiceId;

        const payloadBase = {
          ...criterion,
          ...(child?.id && {
            created_for: `/api/users/${child.id}`,
          }),
        };

        function getPathname(
          criterionChoiceId: number | null,
        ): UriTemplate {
          if (criterionChoiceId) {
            return `criterion_choice_users/${criterionChoiceId}`;
          }
          return 'criterion_choice_users';
        }

        function getMethod(
          criterion: UpdatingDataValue,
          criterionChoiceId: number | null,
        ): string {
          if (criterion === null) {
            return 'DELETE';
          }
          if (criterionChoiceId) {
            return 'PUT';
          }
          return 'POST';
        }

        return fetchItem<Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>>({
          pathname: getPathname(criterionChoiceId),
          headers: {
            acceptLanguage: AcceptLanguage[locale],
          },
          method: getMethod(criterion, criterionChoiceId),
          revalidate: 0,
          body: {
            ...payloadBase,
          },
        });
      }),
    );

    promise.forEach(async (response) => {
      if (response['hydra:description'])
        return setError(response);

      await onSubmit({
        callback: async () => updateUserCriteria(response),
      });
    });
  };

  const getUserCriteria = useCallback(
    async (filter = userCriteriaFilter) => {
      if (!!currentUser?.id && !!locale && !!filter) {
        const response = await fetchCollection<CriteriaChoiceUserType>({
          pathname: isMainUser(currentUser)
            ? `users/${currentUser.id}/criterion_choice_users`
            : `guest_users/${currentUser.id}/criterion_choice_users`,
          headers: {
            acceptLanguage: AcceptLanguage[locale],
          },
          revalidate: 0,
          searchParams: {
            'groups[]': 'criterion_choice_translations',
            ...filter,
          },
        });

        if (response['hydra:description'])
          return setError(response);

        updateUserCriteria(response['hydra:member'] as Array<
          CriteriaChoiceUserType &
          CriteriaChoiceUserToDelete
        >);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, locale, userCriteriaFilter?.length],
  );

  const uriList = useMemo(() => {
    return userCriteria?.map(criterion => criterion.criterion['@id']);
  }, [userCriteria]);

  useEffect(() => {
    getUserCriteria();
  }, [getUserCriteria]);

  return {
    isFetching: getStatus === RequestStatus.PENDING,
    isUpdating: submitStatus === RequestStatus.PENDING,
    getUserCriteria,
    submit,
    uriList,
    userCriteria,
  };
}
