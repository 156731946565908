import(/* webpackMode: "eager", webpackExports: ["CompleteProfileBannerHomepage"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/(home)/components/CompleteProfileBannerHomepage/CompleteProfileBannerHomepage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FollowedProjects"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/(home)/components/FollowedProjects/FollowedProjects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MoreProjectsButton"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/(home)/components/MoreProjects/MoreProjectsButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProjectsScroller"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/(home)/components/ProjectsScroller/ProjectsScroller.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/projects/components/ProjectsFilters.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/projects/components/ProjectsFiltersSports/ProjectsFiltersSports.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/projects/components/ProjectsFiltersStatus.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/projects/components/ProjectsList.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/app/[locale]/projects/components/ProjectsListItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/node_modules/.pnpm/next-intl@3.25.1_next@14.2.14_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/node_modules/.pnpm/next-intl@3.25.1_next@14.2.14_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/node_modules/.pnpm/next-intl@3.25.1_next@14.2.14_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/cocreation-front/cocreation-front/node_modules/.pnpm/next@14.2.14_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1_sass@1.81.0/node_modules/next/dist/client/image-component.js");
