import { useLocale, useTranslations } from 'next-intl';
import classNames from 'classnames';
import { VpIcon } from '@vtmn-play/react/headless';
import { ResizedImg } from '../commons/ResizedImg/ResizedImg';
import { ProjectItemContextEnum } from '../../../app/[locale]/(home)/components/ProjectsScroller/model';
import {
  checkIfProjectIsFinished,
  getProjectTagLabel,
} from '../Projects/utils';
import { Link } from '@/i18n/routing';
import type { ProjectType } from '~/model/ProjectType';
import {
  defineLangValue,
  ellipsis,
  extractDescription,
  getIdFromUri,
} from '~/utils/utils';
import { AnalyticEvents, entityRoutes } from '~/const/appConst';

import { dataLayerPush } from '~/utils/analytics/dataLayerPush';
import type { ProjectTagType } from '~/model/ProjectTag';
import { AcceptLanguage } from '~/const/locale';

export const ProjectsListItem = ({
  project,
  instanceName = ProjectItemContextEnum.all,
  imagePriority = false,
  imageSizes = '(max-width: 768px) 50vw, (max-width: 992px) 33vw, 25vw',
  tags = [],
}: ProjectsListItemPropsType) => {
  const t = useTranslations();

  const userLanguage = useLocale();

  const originalLocale = project.original_locale;

  const isCrossSell = instanceName === ProjectItemContextEnum.cross_sell;
  const isHpGeneric = instanceName === ProjectItemContextEnum.homepageAll;
  const isTopProject = instanceName === ProjectItemContextEnum.top_projects;
  const isGeneric = instanceName === ProjectItemContextEnum.all;
  const isStaring = instanceName === ProjectItemContextEnum.staring;
  const isFollowed = instanceName === ProjectItemContextEnum.followed;

  const horizontal = isGeneric || isTopProject || isStaring;

  const projectIsFinished = checkIfProjectIsFinished(project);

  const matchingTag = tags?.find(
    // Find matching tag and assure that it is not a forged one
    // via its "interaction_type" value
    tag => tag.project_id === project.id && tag.interaction_type,
  );

  // If a matching tag exists, point to the corresponding interaction page
  const href = matchingTag
    ? `/${entityRoutes.interaction}/${matchingTag.interaction_id}`
    : `/${entityRoutes.project}/${getIdFromUri(project['@id'])}`;

  const langValue = defineLangValue(originalLocale, AcceptLanguage[userLanguage]);
  const sizes = isFollowed
    ? '(max-width: 768px) 42vw, (max-width: 992px) 30vw, 16vw'
    : imageSizes;

  return (
    <article
      className={classNames('project-item', {
        'project-item--horizontal': horizontal,
      })}
      title={project?.title}
    >
      <Link
        aria-label={project?.title}
        className="project-item__link"
        href={href}
        onClick={() => {
          isCrossSell && dataLayerPush({
            event: AnalyticEvents.CROSS_SELL,
          });
        }}
      >
        <header className="project-item__header">
          {project.media && (
            <>
              <span className="project-item__media">
                <ResizedImg
                  alt=""
                  aria-hidden="true"
                  fallBackFormat="1600x0"
                  format={`${isStaring ? 768 : 550}x0`}
                  fill
                  sizes={sizes}
                  priority={imagePriority}
                  style={{ objectFit: 'cover' }}
                  src={project.media?.content_url}
                />
              </span>
              {(isStaring || isTopProject || isHpGeneric || isCrossSell) && (
                <span className="project-item__goto">
                  <VpIcon name="arrow-right" size={20} />
                </span>
              )}
              {(isFollowed || isGeneric || isCrossSell)
              && !projectIsFinished
              && matchingTag && (
                <span className="project-item__sticker project-item__sticker--new">
                  {t(getProjectTagLabel(matchingTag))}
                </span>
              )}
              {(isFollowed || isGeneric) && projectIsFinished && (
                <span className="project-item__sticker project-item__sticker--finished">
                  {t('PROJECT.STATUS.FINISHED')}
                </span>
              )}
            </>
          )}
        </header>
      </Link>
      <footer className="project-item__body">
        <Link
          className="project-item__text-link"
          href={href}
          prefetch={false}
          tabIndex={-1}
        >
          {isStaring
            ? (
              <>
                <span lang={langValue} className="project-item__title vp-title-s">
                  {project?.title}
                </span>
                <span
                  lang={langValue}
                  className="project-item__description vp-body-m"
                >
                  {extractDescription(project?.body, 150)}
                </span>
              </>
              )
            : (
              <span lang={langValue} className="project-item__title vp-body-s">
                {ellipsis(project?.title, 80)}
              </span>
              )}
        </Link>
      </footer>
    </article>
  );
};

export interface ProjectsListItemPropsType {
  project: ProjectType;
  instanceName?: ProjectItemContextEnum;
  imagePriority?: boolean;
  imageSizes?: string;
  tags?: ProjectTagType[];
}
