'use client';

import type { ContextType } from 'react';
import { useCallback } from 'react';
import throttle from 'lodash/throttle';
import type { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import type { ProjectScrollerContentType } from './model';
import { ProjectItemContextEnum } from './model';
import { checkIsArray } from './utils';
import { LeftArrow, RightArrow } from './Arrow/Arrow';
import { ProjectsListItem } from '~/components/ProjectsListItem/ProjectsListItem';
import type { ProjectType } from '~/model/ProjectType';
import { getIdFromUri } from '~/utils/utils';
import {
  applyMemorizedScrollerPosition,
  memorizeScroll,
} from '~/components/utils';
import { FollowedProjectsScrollerTail } from '@/app/[locale]/(home)/components/FollowedProjects/FollowedProjectsScroller/FollowedProjectsScrollerTail';

export const Card = ({
  itemId,
  content,
  instanceName,
}: {
  itemId: string;
  content: ProjectScrollerContentType;
  instanceName: ProjectItemContextEnum;
}) => {
  if (Array.isArray(content)) {
    return (
      <>
        {content.map((project: ProjectType) => (
          <ProjectsListItem
            key={`item-${itemId}-${project.title}`}
            project={project}
            instanceName={instanceName}
            imageSizes="(max-width: 768px) 45vw, (max-width: 992px) 25vw, 15vw"
          />
        ))}
      </>
    );
  }
  return (
    <ProjectsListItem
      project={content}
      instanceName={instanceName}
      imageSizes="100vw"
    />
  );
};

export const ProjectsScroller = ({
  instanceName = ProjectItemContextEnum.all,
  data = [],
}: {
  instanceName?: ProjectItemContextEnum;
  data: ProjectScrollerContentType[];
}) => {
  // check if first item is a project or an Array of projects
  const isGroup = checkIsArray(data[0]);
  const isFollowedProjects = instanceName === ProjectItemContextEnum.followed;

  const saveScrollPosition = useCallback(
    throttle(
      ({ scrollContainer }: scrollVisibilityApiType) =>
        !!scrollContainer.current
        && memorizeScroll(instanceName, scrollContainer.current.scrollLeft),
      500,
    ),
    [],
  );

  const restoreScrollPosition = useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) => {
      // restore exact position by pixels
      applyMemorizedScrollerPosition(instanceName, scrollContainer);
    },
    [],
  );

  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      wrapperClassName={`${instanceName}-scroller`}
      itemClassName={`${instanceName}-scroller__item`}
      scrollContainerClassName={`${instanceName}-scroller__container`}
      onInit={restoreScrollPosition}
      onScroll={saveScrollPosition}
    >
      {data
        .map((projectOrGroup) => {
          // Store the URI of the fist item
          // if the current item is an Array
          const uri: string = isGroup
            ? projectOrGroup[0]['@id']
            : projectOrGroup['@id'];
          const id = getIdFromUri(uri);
          const uniqueKey = `${instanceName}-${id}`;
          return (
            <Card
              key={`card-${uniqueKey}`}
              itemId={uniqueKey}
              content={projectOrGroup}
              instanceName={instanceName}
            />
          );
        })
        // Add extra card for followed projects
        .concat(
          isFollowedProjects
            ? [<FollowedProjectsScrollerTail key="followed-projects-tail" />]
            : [],
        )}
    </ScrollMenu>
  );
};

export type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;
