import { useEffect, useMemo, useState } from 'react';
import type { UriType } from '~/model/GlobalTypes';

export function useCriteriaCompletion(
  criteria: UriType[],
  userCriteria: UriType[],
) {
  const [total, setTotal] = useState<number>();

  const missing = useMemo(() => {
    if (!userCriteria)
      return;
    return criteria?.filter(criterion => !userCriteria?.includes(criterion));
  }, [criteria, userCriteria]);

  const currentCriterion = useMemo(() => {
    const current = missing?.[0];
    return {
      criterion: current,
      userCriterion: userCriteria?.find(criterion => criterion === current),
    };
  }, [missing, userCriteria]);

  useEffect(() => {
    !!missing && missing.length >= (total || 0) && setTotal(missing.length);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missing]);

  return { currentCriterion, missing, total };
}
