'use client';

import { useTranslations } from 'next-intl';
import { VtmnSkeleton } from '@vtmn/react';
import { VpButton, VpIcon, VpIconButton } from '@vtmn-play/react/headless';
import { FollowedProjectsIcon } from './FollowedProjectsIcon';
import { useAuthenticatedContext } from '@/app/components/Provider';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';
import { Link } from '@/i18n/routing';
import appConst from '~/const/appConst';
import { useUserData } from '@/app/hooks/useUserData';

export const EmptyFollowedProject = () => {
  const t = useTranslations();
  const { user, isAuthenticated, redirectToLogin } = useAuthenticatedContext();

  const authenticatedAsMainUser = isAuthenticatedAsMainUser(
    user,
    isAuthenticated,
  );

  const { userData } = useUserData(['contribution_count']);

  const { my_participations, projects } = appConst.staticRoutes;

  const authenticatedCallToAction
    = typeof userData?.contribution_count === 'number'
      ? (
        <Link
          className="vp-button vp-button--secondary button-wrapper-link"
          href={userData?.contribution_count ? my_participations : projects}
          prefetch={false}
        >
          <span className="vp-button__label" data-part="label">
            {t(userData?.contribution_count
              ? 'HOMEPAGE.FOLLOWED.ALL_MY_PARTICIPATIONS'
              : 'HOMEPAGE.FOLLOWED.ALL_PROJECTS',
            )}
          </span>
        </Link>
        )
      : (
        <VpButton variant="secondary" disabled>
          <VtmnSkeleton width="240px" height="24px" />
        </VpButton>
        );

  const callToAction = !authenticatedAsMainUser
    ? (
      <VpButton
        variant="secondary"
        onClick={redirectToLogin}
      >
        {t('GLOBAL.LOGIN_OR_CREATE_ACCOUNT')}
      </VpButton>
      )
    : (
        authenticatedCallToAction
      );

  return (
    <div className="followed-projects__content">
      <div className="followed-projects__animation">
        <FollowedProjectsIcon />
        {!authenticatedAsMainUser
          ? (
            <VpIconButton
              aria-label={t('GLOBAL.LOGIN_OR_CREATE_ACCOUNT')}
              data-testid="participations"
              size="small"
              onClick={redirectToLogin}
            >
              <VpIcon name="add" />
            </VpIconButton>
            )
          : (
            <Link
              aria-label={t(userData?.contribution_count
                ? 'HOMEPAGE.FOLLOWED.ALL_MY_PARTICIPATIONS'
                : 'HOMEPAGE.FOLLOWED.ALL_PROJECTS',
              )}
              className="vp-icon-button vp-icon-button--small button-wrapper-link"
              href={userData?.contribution_count ? my_participations : projects}
              prefetch={false}
            >
              <VpIcon name="add" />
            </Link>
            )}
      </div>
      <div className="followed-projects__incentive">
        <div className="followed-projects__main-text">
          <h2 className="vp-subtitle-l">{t('HOMEPAGE.NO_FOLLOW.MAIN')}</h2>
          <p className="homepage__secondary-text vp-body-m">
            {t('HOMEPAGE.NO_FOLLOW.SECONDARY')}
          </p>
        </div>
        {callToAction}
      </div>
    </div>
  );
};
