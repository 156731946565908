import { useCallback, useEffect, useState } from 'react';
import { fetchItem } from '../actions/server';
import { useApiErrorContext, useAuthenticatedContext } from '@/app/components/Provider';

export enum UserDataProfileCompletion {
  CHILDREN = 'CHILDREN',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  DIGITAL_USAGE = 'DIGITAL_USAGE',
  MEASUREMENT = 'MEASUREMENT',
  PROFILE = 'PROFILE',
  SPORTS = 'SPORTS',
}

export interface UserDataType {
  contribution_count?: number;
  profile_completion?: Record<UserDataProfileCompletion, number>;
}

export function useUserData(filter: Array<keyof UserDataType>) {
  const { setError } = useApiErrorContext();
  const { user } = useAuthenticatedContext();
  const [userData, setUserData] = useState<UserDataType>();

  const handleFetchUserData = useCallback(async () => {
    if (!user?.id)
      return;

    const response = await fetchItem<UserDataType>({
      pathname: `users/${user.id}/data`,
      revalidate: 0,
      searchParams: {
        'groups[]': filter,
      },
    });

    if (response['hydra:description'])
      return setError(response);

    setUserData(response);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    handleFetchUserData();
  }, [handleFetchUserData]);

  return { userData, update: handleFetchUserData };
}
