'use client';

import Link from 'next/link';
import { dataLayerPush } from '@/app/utils/analytics/datalayer';
import appConst from '~/const/appConst';

export const MoreProjectsButton = ({
  buttonLabel,
  analyticsEvent,
}) => {
  return (
    <Link
      className="vp-button vp-button--squared button-wrapper-link"
      href={appConst.staticRoutes.projects}
      prefetch={false}
      onClick={() => {
        analyticsEvent && dataLayerPush({
          event: analyticsEvent,
        });
      }}
    >
      <span className="vp-button__label" data-part="label">
        {buttonLabel}
      </span>
    </Link>

  );
};
